import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Modal,
    Notification,
    Group,
    Text,
    Loader,
    Tabs
} from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import {
    IconCheck,
    IconAlertCircle,
    IconTrash
} from '@tabler/icons-react';
import { makeGetRequest, makeDeleteRequest } from '../services/httpHelper';
import { IDeletePersonalDataRequest } from '../types';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import AddRoleForm from '../components/AddRoleForm';

const PAGE_SIZE = 10;

const ManageDeletePersonalData = () => {
    const [requests, setRequests] = useState<IDeletePersonalDataRequest[]>([]);

    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState<IDeletePersonalDataRequest[]>([]);
    const [activeTab, setActiveTab] = useState<'pending' | 'completed'>('pending');

    
    const fetchRequests = async () => {
        const res = await makeGetRequest('delete-personal-data-requests');
        setIsLoading(false);
        if (isErrorObject(res)) {
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            setRequests(res.data);
        }
    };

    useEffect(() => {
      
        fetchRequests();
    }, []);

    useEffect(() => {
        const filteredRecords = activeTab === 'pending'
            ? (Array.isArray(requests)?requests.filter(req => !req.deleted):[])
            : (Array.isArray(requests)?requests.filter(req => req.deleted):[]);
        setRecords(filteredRecords.slice(0, PAGE_SIZE));
    }, [requests, activeTab]);

    const handlePageChange = (page: number) => {
        setPage(page);
        const filteredRecords = activeTab === 'pending'
            ? requests.filter(req => !req.deleted)
            : requests.filter(req => req.deleted);
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setRecords(filteredRecords.slice(from, to));
    };

    const handleDeleteData = async (requestId: string) => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');

        const confirmed = window.confirm('Are sure you want to delete user personal data. This action cannot be reversed?')

       if(confirmed){
        const res = await makeDeleteRequest(`delete-personal-data/${requestId}/${user.id}`);
        if (isErrorObject(res)) {
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            fetchRequests();
            setSuccess('Personal Data Deleted Successfully');
            setRequests(requests.map(req => req.id === requestId ? { ...req, deleted: true } : req));
        }
       }
    };

    return (
        <Box mx="auto" p="md">
            {error && (
                <Notification icon={<IconAlertCircle size={18} />} color="red" mb="lg">
                    {error}
                </Notification>
            )}
            {success && (
                <Notification icon={<IconCheck size={18} />} color="green" mb="lg">
                    {success}
                </Notification>
            )}

            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Text weight={500} size="lg" mb="md">
                        Delete Personal Data Requests
                    </Text>

                    <Tabs value={activeTab} onTabChange={(value) => setActiveTab(value as "pending" | "completed")}>

                        <Tabs.List>
                            <Tabs.Tab value="pending">Pending Requests</Tabs.Tab>
                            <Tabs.Tab value="completed">Completed Requests</Tabs.Tab>
                        </Tabs.List>
                    </Tabs>

                    <DataTable
                        columns={[
                            { accessor: 'full_name', title: 'Full Name' },
                            { accessor: 'mobile_number', title: 'Mobile Number' },
                            { accessor: 'reason', title: 'Reason' },
                            { accessor: 'createdAt', title: 'Created At', render: row => new Date(row.createdAt).toLocaleString() },
                            { accessor: 'verified', title: 'Verified', render: row => (row.verified ? 'Yes' : 'No') },
                            { accessor: 'deleted', title: 'Deleted', render: row => (row.deleted ? 'Yes' : 'No') },
                            { accessor: 'date_deleted', title: 'Date Deleted', render: row => row.date_deleted ? new Date(row.date_deleted).toLocaleString() : 'N/A' },
                            {
                                accessor: 'actions',
                                title: 'Actions',
                                render: (request: IDeletePersonalDataRequest) => (
                                    <Group spacing="sm">
                                        {!request.deleted && (
                                            <Button
                                                leftIcon={<IconTrash size={16} />}
                                                size="xs"
                                                color="red"
                                                onClick={() => handleDeleteData(request.id)}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </Group>
                                ),
                            },
                        ]}
                        records={records}
            totalRecords={activeTab === 'pending'
                ? (Array.isArray(requests) ? requests.filter(req => !req.deleted).length : 0)
                : (Array.isArray(requests) ? requests.filter(req => req.deleted).length : 0)
            }
            recordsPerPage={PAGE_SIZE}
            page={page}
            onPageChange={handlePageChange}
            highlightOnHover
            verticalSpacing="sm"
                    />

                    <Modal opened={showAddModal} onClose={() => setShowAddModal(false)} title="Add Role">
                        <AddRoleForm />
                    </Modal>
                </>
            )}
        </Box>
    );
};

export default ManageDeletePersonalData;
