import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {ICampaign, ICampaignTeam, IDonation} from "../types";
import {
   // Accordion,
    ActionIcon,
    Anchor,
    Avatar,
    Box,
    Button,
    Card,
    Container,
    Divider,
    Flex,
    Grid,
    Group,
    Loader,
    Paper,
    PaperProps,
    Progress,
    Stack,
    Text,
    TextProps,
    Title,
    TitleProps,
    UnstyledButton
} from "@mantine/core";
import {IconFlag, IconHeart, IconHeartFilled, IconSeparator, IconShare} from "@tabler/icons-react";
import {useDisclosure, useMediaQuery, useToggle} from "@mantine/hooks";
import {BackButton, DonationDrawer,  NotFound, ShareModal, UserCard} from "../components";
import {Helmet} from "react-helmet";
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import {notifications} from "@mantine/notifications";
import { isErrorObject, toTitleCase } from "../utils/utils";
import { makeGetRequest, makePostRequest } from "../services/httpHelper";
import ErrorHandler from "../services/ErrorHandler";
import { formatCurrency } from "../utils/FormValidator";
import { Link } from "react-router-dom";
import CampaignDonorsTable from "../components/CampaignDonorsTable";
import CampaignTeamTable from "../components/CampaignTeamTable";
import SocialShare from "../components/SocialShare";
import { IMAGE_BASE_URL } from "../services/paths";
import ErrorBoundary from "../utils/ErrorBoundary";

const CampaignDetailsPage = (): JSX.Element => {
    dayjs.extend(LocalizedFormat)
    const {id} = useParams();
    const [campaign, setCampaign] = useState<ICampaign>();
    const [opened, {open, close}] = useDisclosure(false);
    const [donateOpened, {open: donateOpen, close: donateClose}] = useDisclosure(false);
    const [following, setFollowing] = useToggle();
    const matchesMobile = useMediaQuery('(max-width: 768px)');
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [donations, setDonations] = useState<IDonation[]>([]);
    const[totalFollowers,setTotalFollowers] = useState(0)
    const [teams, setTeams] = useState<ICampaignTeam[]>([]);

const campaignImage = IMAGE_BASE_URL+campaign?.image;
 



    const getProgress =(raised:number,target:number):number=>{
        var progress:number = (raised/target)*100;
       
    
        return progress;
    
    }

    const paperProps: PaperProps = {
        p: "md",
        shadow: "sm",
    }

    const titleProps: TitleProps = {
        size: 32,
        weight: 700,
        transform: 'capitalize',
        sx: {lineHeight: '40px'}
    }

    const subTitleProps: TextProps = {
        size: 20,
        weight: 600,
        sx: {lineHeight: '28px'}
    }

    const iconSize = 18;
    {}

    const followCampaign = async () => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');

        const data = {
            user_id: user.id,
            campaign_id: id,
        }
        const res = await makePostRequest('follow-campaign', data);

        if (res && res.errorType) {
            setError(res.errorMessage)
        } else {
           
            //setFollowing()    
            setFollowing(!following)    

}
    }


    const totalRaised = ():number=>{
        let total =0;
        const campaignDonations = donations && donations.filter((donation)=>donation.campaign_id===id);
        campaignDonations && campaignDonations.map((d)=>{
            total += parseFloat(d.donation_amount);
        })

        return total;
    }

        useEffect(() => {
            const fetchCampaign = async () => {
                setIsLoading(true);
                const res = await makeGetRequest( `campaign/${id}`);
                if (isErrorObject(res)) {
                    setIsLoading(false);
                    const errorMessage = ErrorHandler(res);
                    setError(errorMessage);
                  } else {
                    setCampaign(res.data);
                    setTotalFollowers(res.data.campaignfollowers.length)
                }
                setIsLoading(false);
            };

            const fetchDonations = async () => {
                setIsLoading(true);
                const endpoint =`campaign-donors/${id}`;
                const res = await makeGetRequest(endpoint);
                if (isErrorObject(res)) {
                    setIsLoading(false);
                    const errorMessage = ErrorHandler(res);
                    setError(errorMessage);
                } else {
                    console.log('teams',campaign?.teams)
                    setDonations(res.data);
                    setIsLoading(false);
    
                }
            };


            const campaignPromoters = async () => {
                setIsLoading(true);
                const endpoint =`campaign-team/${id}`;
                const res = await makeGetRequest(endpoint);
                if (isErrorObject(res)) {
                    setIsLoading(false);
                    const errorMessage = ErrorHandler(res);
                    setError(errorMessage);
                } else {
                    console.log('Teams',res.data)
                    setTeams(res.data);
                    setIsLoading(false);
    
                }
            };
           fetchCampaign();
           fetchDonations();
           campaignPromoters();
        }, [id]);

    

    return (
        <>
        <ErrorBoundary>

       
        {isLoading && <Loader/>}
        {error && <p className="error">{error}</p>}
            <Helmet>
                <title>{campaign?.title}</title>
            </Helmet>
            <Box>
                {campaign ? <Container size="lg">
                    <BackButton mb="md"/>
                    <Grid>
                        <Grid.Col lg={8}>
                            <Stack>
                                <Card padding="md" shadow="sm">
                                    <Card.Section>
                                  
                                         <img crossOrigin="anonymous" src={`${campaignImage}`} height={480}/>
                                    </Card.Section>
                                    <Stack mt="md">
                                        {!matchesMobile ?
                                            <Flex gap="xs" align="center">
                                                <Text size="sm">Campaign created by</Text>
                                                <UnstyledButton component={Anchor}>
                                                    <Flex gap="xs" align="center">
                                                        <Avatar src={campaign?.image} radius="xl" size="sm"/>
                                                        <Text size="sm">{campaign?.fundRaiser.first_name} {campaign?.fundRaiser.last_name}</Text>
                                                    </Flex>
                                                </UnstyledButton>
                                                <IconSeparator size={18}/>
                                                <Text component={Anchor} size="sm">{campaign?.country}</Text>
                                                <IconSeparator size={18}/>
                                                <Text component={Anchor} size="sm">{campaign && toTitleCase(campaign.category.category)}</Text>
                                            </Flex> :
                                            <Stack>
                                                <Flex gap="md">
                                                    <Text size="sm">Fundraise campaign created by</Text>
                                                    <UnstyledButton component={Anchor}>
                                                        <Flex gap="xs" align="center">
                                                            <Avatar src={campaign.fundRaiser?.avatar } radius="xl"
                                                                    size="sm"/>
                                                            <Text size="sm">{campaign?.fundRaiser.first_name} {campaign?.fundRaiser.last_name}</Text>
                                                            
                                                        </Flex>
                                                    </UnstyledButton>
                                                </Flex>
                                                <Group>
                                                    <Text size="sm">Location
                                                        - <Anchor>{toTitleCase(campaign?.country)}</Anchor></Text>
                                                    <Text size="sm">Category
                                                        - <Anchor>{toTitleCase(campaign?.category.category)}</Anchor></Text>
                                                                                                                                                                </Group>
                                                
                                            </Stack>
                                        }
                                        <Text {...subTitleProps}>Our story</Text>
                                        <Text size="sm">{campaign?.description}</Text>
                                        {matchesMobile && <>
                                            <Divider/>
                                            <Flex align="flex-end" gap="sm">
                                                <Title {...titleProps} align="center">{totalRaised()}</Title>
                                                <Text fw={500} align="center" color="dimmed">raised
                                                    of {formatCurrency(parseFloat(campaign?.target.toString()))}</Text>
                                            </Flex>
                                            <Progress value={7} size="md"/>
                                            <Flex justify="space-between">
                                                <Text fw={500}>{getProgress(parseInt(totalRaised().toString()),parseFloat(campaign.target.toString())).toFixed(2)}% Funded</Text>
                                                <Text fw={500}>{donations.length} Donors</Text>
                                            </Flex>
                                            <Flex align="center" gap="xs">
                                                <Button onClick={donateOpen} fullWidth>Donate</Button>
                                                <ActionIcon
                                                    variant="subtle"
                                                    onClick={open}
                                                    color="blue"
                                                    title="Share with your friends"
                                                    size="lg"
                                                >
                                                    <IconShare size={iconSize}/>
                                                </ActionIcon>
                                                <ActionIcon
                                                    title={`${following ? 'Unfollow' : 'Follow'} this campaign`}
                                                    // variant={following ? 'filled' : 'subtle'}
                                                    size="lg"
                                                    color={'secondary'}
                                                    onClick={() => {
                                                        followCampaign();
                                                        notifications.show({
                                                            title: 'Notification',
                                                            message: `${following ? 'Following' : 'Unfollowed'} this campaign`,
                                                            withBorder: true,
                                                            styles: (theme) => ({
                                                                root: {
                                                                    backgroundColor: theme.colors.blue[6],
                                                                    borderColor: theme.colors.blue[6],

                                                                    '&::before': {backgroundColor: theme.white},
                                                                },

                                                                title: {color: theme.white},
                                                                description: {color: theme.white},
                                                                closeButton: {
                                                                    color: theme.white,
                                                                    '&:hover': {backgroundColor: theme.colors.blue[7]},
                                                                },
                                                            }),
                                                        })
                                                    }}
                                                >
                                                    {following ?
                                                        <IconHeartFilled size={iconSize}/> :
                                                        <IconHeart size={iconSize}/>
                                                    }
                                                </ActionIcon>
                                            </Flex>
                                        </>}
                                    </Stack>
                                </Card>
                                <Paper {...paperProps}>
                                    <Text {...subTitleProps} mb="sm">Organizer</Text>
                                    <UserCard campaignId={id ?? ''} />

                                </Paper>


                                <Paper {...paperProps}>
                                <Text>
        Created on {campaign?.createdAt ? dayjs(campaign.createdAt).isValid() ? dayjs(campaign.createdAt).format('LL') : 'Invalid date' : 'Date not available'}
    </Text>
                                </Paper>
                                <Paper {...paperProps}>
                                <Text>Followers {totalFollowers}</Text>                                
                                </Paper>

                                
                                    
                               
                                <Paper {...paperProps}>
                                    <Text {...subTitleProps} mb="sm">Promoters</Text>
                                    <CampaignTeamTable teams={teams}/>
                                </Paper>



                                {!matchesMobile &&
                                   <Link to="/contact-us">
                                    <Button
                                        leftIcon={<IconFlag size={iconSize}/>}
                                        variant="subtle"
                                        color="secondary"
                                    >
                                        Report campaign
                                    </Button>
                                   </Link>
                                }


                        <SocialShare
                        title={campaign?.title || "Support this campaign"}
                        caption={campaign?.description?.split(" ").slice(0, 30).join(" ") + "..."}
                        image={campaignImage}
                        link={window.location.href}
                        />

                            </Stack>
                        </Grid.Col>
                        <Grid.Col lg={4}>
                            <Stack>
                                {!matchesMobile &&
                                    <Paper {...paperProps}>
                                        <Stack spacing="sm">
                                            <Title {...titleProps} align="center">{campaign.currency==='usd'?'$':campaign.currency}{formatCurrency(parseFloat(totalRaised().toString()))}</Title>
                                            <Text fw={500} align="center" color="dimmed">raised
                                                of {campaign?.target}</Text>
                                            <Progress value={7} size="md"/>
                                            <Flex justify="space-between">
                                                <Text fw={500}>{getProgress(parseFloat(totalRaised().toString()),parseFloat(campaign.target.toString())).toFixed(2)}% Funded</Text>
                                                <Text fw={500}>{campaign?.donors} Donors</Text>
                                            </Flex>
                                            <Button size="xl" onClick={donateOpen}>Donate</Button>
                                            <Button
                                                leftIcon={<IconShare size={iconSize}/>}
                                                variant="outline"
                                                onClick={open}
                                                color="blue"
                                            >
                                                Share with friends
                                            </Button>
                                            <Button
                                                leftIcon={following ? <IconHeartFilled size={iconSize}/> :
                                                    <IconHeart size={iconSize}/>}
                                                variant={following ? 'filled' : 'subtle'}
                                                color="secondary"
                                                onClick={() => {
                                                    followCampaign();
                                                    notifications.show({
                                                        title: 'Notification',
                                                        message: `${following ? 'Following' : 'Unfollowed'} this campaign`,
                                                        withBorder: true,
                                                        styles: (theme) => ({
                                                            root: {
                                                                backgroundColor: theme.colors.blue[6],
                                                                borderColor: theme.colors.blue[6],

                                                                '&::before': {backgroundColor: theme.white},
                                                            },

                                                            title: {color: theme.white},
                                                            description: {color: theme.white},
                                                            closeButton: {
                                                                color: theme.white,
                                                                '&:hover': {backgroundColor: theme.colors.blue[7]},
                                                            },
                                                        }),
                                                    })
                                                }}
                                            >
                                                {following ? 'Unfollow' : 'Follow'} this campaign
                                            </Button>
                                        </Stack>
                                    </Paper>
                                }
                              
                                <Paper>
                                <Text {...subTitleProps}>Donors</Text>
                                    <CampaignDonorsTable donations={donations}/>
                                </Paper>
                                {matchesMobile &&
                                    <Button
                                        leftIcon={<IconFlag size={iconSize}/>}
                                        variant="subtle"
                                        color="secondary"
                                    >
                                        Report campaign
                                    </Button>
                                }
                            </Stack>
                        </Grid.Col>
                    </Grid>
                </Container> : <NotFound/>}
                <ShareModal opened={opened} onClose={close} campaign={campaign} iconSize={iconSize}/>
                <DonationDrawer campaign={campaign} opened={donateOpened} onClose={donateClose} iconSize={iconSize}/>
            </Box>
            </ErrorBoundary>
        </>
    );
};

export default CampaignDetailsPage;
