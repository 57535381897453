import { useEffect, useState } from 'react';
import {
    ActionIcon,
    Anchor,
    Button,
    Checkbox,
    Container,
    Drawer,
    DrawerProps,
    Flex,
    Group,
    Loader,
    NumberInput,
    Paper,
    PaperProps,
    Popover,
    Radio,
    ScrollArea,
    Select,
    Slider,
    Stack,
    Text,
    TextInput,
    ThemeIcon,
    Transition,
    useMantineTheme,
} from "@mantine/core";
import {
    IconBrandApple,
    IconBrandGoogle,
    IconCreditCard,
    IconCurrencyDollar,
    IconInfoCircleFilled,
    IconShieldCheckFilled,
    
} from "@tabler/icons-react";
import { makeGetRequest, makePostRequest } from '../services/httpHelper';
import { isErrorObject } from '../utils/utils';
import ErrorHandler from '../services/ErrorHandler';
import { ICurrency,ICampaign,IPaymentMethod,IBank } from '../types';
import { IMAGE_BASE_URL } from '../services/paths';
//import DonationThankYouPage from './DonationThankYouPage';
//import PaymentFailed from './PaymentFailed';
import { Navigate } from 'react-router-dom';




interface IProps extends Pick<DrawerProps, 'opened' | 'onClose' | 'size'> {
    campaign?: ICampaign;
    iconSize: number;
}

const DonationDrawer = ({ campaign, iconSize, ...others }: IProps) => {
    const [payment, setPayment] = useState('');
    const [supportedCurrencies, setSupportedCurrencies] = useState<ICurrency[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const [banks, setBanks] = useState<IBank[]>([]);
    const [currentPaymentMethod, setCurrentPaymentMethod] = useState('M-PESA');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [selectedBank, setSelectedBank] = useState('');
    const [donationAmount, setDonationAmount] = useState(0);
    const [tipPercentage, setTipPercentage] = useState(0);
    const [serviceFee] = useState(0.035);
    const [tipAmount, setTipAmount] = useState(0);
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [showGuestForm, setShowGuestForm] = useState(true);
    const [exchangeRate, setExchangeRate] = useState<number>(1);
    const [isFailed, setIsFailed] = useState(false);



    const imagePath = campaign?.image;
    const firstCharacter = imagePath?.charAt(0) || '';

    const campaignImage = firstCharacter==='/'?IMAGE_BASE_URL+campaign?.image : campaign?.image;


    const [userDetails, setUserDetails] = useState({
        email: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
        cardNumber: 0,
        cardExpiry: '',
        cardCvv: 0,
        cardName: '',
        postalCode: '',
        accountName:'',
        accountNumber:''
    });
  


    const theme = useMantineTheme();
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const isUserLoggedIn = !!user.id;

    const paperProps: PaperProps = {
        p: "md",
        withBorder: true,
        sx: { backgroundColor: theme.white },
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserDetails(prevState => ({ ...prevState, [name]: value }));
    };

    const handleNumberChange = (value: number | any, name: string) => {
        setUserDetails(prevState => ({ ...prevState, [name]: value ?? 0 }));
    };

    /*const handleDonationAmountChange = (value: number | any) => {
        setDonationAmount(value ?? 0);
      
    };*/
    const handleDonationAmountChange = (value: number | any) => {
        const amount = value ?? 0;
        setDonationAmount(amount);
        // Use the callback version to ensure the latest state
        //setTipAmount(tip => (tip / 100) * amount);
    };

    const handleTipAmountChange = (value: number) => {
    setTipPercentage(value );        
    };

    const handlePaymentMethodChange = (paymentMethodId: string) => {
        const selectedPaymentMethod = paymentMethods.filter((paymentMethod:IPaymentMethod)=>paymentMethod.id===paymentMethodId);
        setPayment(paymentMethodId)

        setSupportedCurrencies(selectedPaymentMethod[0].currencies);
        setCurrentPaymentMethod(selectedPaymentMethod[0].payment_service)

      
    };

    const handleCurrencyChange = (value: string) => {
        setSelectedCurrency(value);
        // Fetch exchange rate for selected currency
       // fetchExchangeRate();
    };

    const handleBankChange = (value: string) => {
        setSelectedBank(value);
     
    };

    const fetchExchangeRate = async (/*currency: string*/) => {
        // Implement API call to fetch exchange rate
        const rate = await getExchangeRateFromAPI();
        setExchangeRate(rate);
        fetchExchangeRate();
    };

    const getExchangeRateFromAPI = async (/*currency: string*/) => {

        // Mock API call to fetch exchange rate
       /* const mockRates = {
            USD: 1,
            EUR: 0.85,
            KES: 108,
        };*/
       // return mockRates[currency] || 1;
       return 0.85
    };

    const checkPaymentStatus = async(transactionId:any) => {
        const interval = setInterval(async() => {
          const res = await makeGetRequest(`check-payment-status/${transactionId}`);
          if (isErrorObject(res)) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
            clearInterval(interval);

        }
         else if (res.data.status === 'success') {
            clearInterval(interval);
           setIsSubmitting(false);
            setIsSuccess(true);
          } else if (res.data.status === 'failed') {
            clearInterval(interval);            
            setIsSubmitting(false);
            setIsSuccess(false);
             setIsFailed(true)

          }
        }, 10000); // Check every 10 seconds
      };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const data = {
            userId: isUserLoggedIn ? user.id : null,
            campaignId: campaign?.id,
            donationAmount,
            tipAmount,
            paymentMethod: payment,
            selectedCurrency,
            isAnonymous,
            ...userDetails,
        };

            const res = await makePostRequest('donate', data);

        if (isErrorObject(res)) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            console.log(res)
            const hasApprovalUrl = res.data?.approvalUrl;
            if(hasApprovalUrl){
                const orderId = res.data.orderId;
                //save order ID in the session
                localStorage.setItem('orderId',orderId)
                const approvalLink =res.data.approvalUrl;
                // Redirect the user to PayPal for approval
              window.location.href = approvalLink;
            }else{
                const transactionId = res.data.transactionId;
                checkPaymentStatus(transactionId); // Start polling to check payment status
            }
           
        }

    };

    const getPaymentMethods = async () => {
        setIsLoading(true);
        const res = await makeGetRequest('payment-service-currencies');

        if (isErrorObject(res)) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {

            
            const results = res.data;
            setPaymentMethods(results);
            const defaultPaymentMethod = results && results.filter((paymentMethod:IPaymentMethod)=>paymentMethod.payment_service===currentPaymentMethod);
            setSupportedCurrencies(defaultPaymentMethod[0].currencies);
            setIsLoading(false);
            setError(null);
        }
    };

    /*const convertCurrency = async () => {
        setIsLoading(true);
        const res = await makePostRequest('convert-currency',{
            from:'',
            to:'',
            value:''
        });

        if (isErrorObject(res)) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            const results = res.data;
            setBanks(results);       
            setIsLoading(false);
            setError(null);
        }
    };*/


    const getBanks = async () => {
        setIsLoading(true);
        const res = await makeGetRequest('banks');

        if (isErrorObject(res)) {
            setIsLoading(false);
            const errorMessage = ErrorHandler(res);
            setError(errorMessage);
        } else {
            const results = res.data;
            setBanks(results);       
            setIsLoading(false);
            setError(null);
        }
    };

    const getIcon = (payment_service: string) => {
        switch (payment_service) {
            case 'Google Pay':
                return <IconBrandGoogle size={iconSize} />;
            case 'Apple Pay':
                return <IconBrandApple size={iconSize} />;
            case 'Credit or debit':
                return <IconCreditCard size={iconSize} />;
            default:
                return <IconCreditCard size={iconSize} />;
        }
    };

  

   

 

    useEffect(() => {
        getPaymentMethods();
        getBanks();
    }, []);

    useEffect(() => {
        if (isUserLoggedIn) {
            setShowGuestForm(false);
        }

    }, [isUserLoggedIn]);


    useEffect(() => {
        setTipAmount((tipPercentage / 100) * donationAmount); // Calculate tip when donation amount changes
    }, [tipPercentage,donationAmount]);

   //const navigate = useNavigate();

    if (isLoading) {
        return <Loader />;
    }

   else if (isSuccess) {
    const campaignId = campaign?.id;
    return <Navigate to='/donation-success' state={{
        amount:donationAmount,
        campaignId:campaignId,
        currency:selectedCurrency
    }}/>
   /*return navigate('/donation-success',{state:{
        amount:donationAmount,
        campaignId:campaignId,
        currency:selectedCurrency
    }})*/
       // return <DonationThankYouPage amount={donationAmount} campaignId={campaignId as string} currency={selectedCurrency}/>
    }
    else if (isFailed) {
       return <Navigate to="/payment-failed"/>
    } else{
        return (
            <Drawer
                position="bottom"
                title="Make a Donation"
                size="100%"
                scrollAreaComponent={ScrollArea.Autosize}
                {...others}
            >
                <Container>
                    {error && <p className='error'>{error}</p>}
                    <Stack>
                        <Flex gap="xs" align="center">
    
                            <img  src={campaignImage} height={96} width={120}/>
                            <Text>You&apos;re supporting  <b>{campaign?.title}</b> </Text>
                        </Flex>
                        <NumberInput
                            size="md"
                            label="Enter your donation"
                            precision={2}
                            rightSection={<IconCurrencyDollar size={iconSize} />}
                            value={donationAmount}
                            placeholder='0.00'
                            onChange={handleDonationAmountChange}
                        />
                     {
                        donationAmount &&
                        <Paper {...paperProps}>
                        <Text fw={500}>Tip FundReach services</Text>
                        <Text size="sm" my="xs">FundReach has a 3.5% platform fee for organizers. FundReach will continue offering its services thanks to donors who will leave an optional amount here:</Text>
                        <Slider
                            marks={[
                                { value: 20, label: '20%' },
                                { value: 50, label: '50%' },
                                { value: 80, label: '80%' },
                            ]}
                            mb="lg"
                            onChange={handleTipAmountChange}
                        />
                    </Paper>
                     }
                        <Paper {...paperProps}>
                            <Radio.Group
                                name="paymentMethod"
                                label="Payment method"
                                value={payment}
                                onChange={handlePaymentMethodChange}
                                mb="md"
                            >
                                <Group mt="sm">
                                {paymentMethods.map((method) => (
                        <Radio
                            key={method.id}
                            value={method.id} // Ensure unique values
                            label={<Group spacing="xs">{getIcon(method.payment_service)}<Text>{method.payment_service}</Text></Group>}
                            disabled={method.disbled}
                            //checked={payment === method.payment_service.toLowerCase().replace(/ /g, '')}
                            checked={method.payment_service===currentPaymentMethod}
    
                        />
                    ))}
                                  
                                   {/* <Radio
                                        value="gpay"
                                        label={<Group spacing="xs"><IconBrandGoogle size={iconSize} /><Text>Google Pay</Text></Group>} />
                                    <Radio
                                        value="applepay"
                                        label={<Group spacing="xs"><IconBrandApple size={iconSize} /><Text>Apple Pay</Text></Group>} />
                                    <Radio
                                        value="card"
                                        label={<Group spacing="xs"><IconCreditCard size={iconSize} /><Text>Credit or debit</Text></Group>} />*/}
                                </Group>
                            </Radio.Group>
                           {
                            supportedCurrencies &&
                            <Select
                            label="Currency"
                            value={selectedCurrency}
                            onChange={handleCurrencyChange}
                            data={supportedCurrencies.map(currency => ({ value: currency.cc, label: `${currency.symbol} ${currency.name}` }))}
                        />
                           }
    
    
                                {
                            currentPaymentMethod.toLocaleLowerCase()==="bank" &&
                          
    
                        
                        <Paper
                        p="md"
                        radius="sm"
                        mt="sm"
                    >
                        <Stack sx={{ width: '100%' }}>
                        <Group grow>
                        <Select
                            label="Your Bank"
                            value={selectedBank}
                            onChange={handleBankChange}
                            data={banks.map(bank => ({ value: bank.bank_name, label: `${bank.bank_name} ` }))}
                        />
    
                    <TextInput label="Account Name" name="account_name" value={userDetails.accountName} onChange={handleChange} />
                    <TextInput label="Account Number" name="account_number" value={userDetails.accountNumber} onChange={handleChange} />
    
                            </Group>
    
                        </Stack>
    
    
                        </Paper>
                           }
    
    
    
    
    {
                            currentPaymentMethod.toLocaleLowerCase()==="m-pesa" &&
                          
    
                        
                        <Paper
                        p="md"
                        radius="sm"
                        mt="sm"
                    >
                        <Stack sx={{ width: '100%' }}>
                        <Group grow>
                      
    
                    <TextInput label="First name" name="firstName" value={userDetails.firstName} onChange={handleChange} />
                    <TextInput label="Last name" name="lastName" value={userDetails.lastName} onChange={handleChange} />
                                                    
                <TextInput label="Mpesa Mobile Number" name="mobileNumber" value={userDetails.mobileNumber} onChange={handleChange} />
    
                            </Group>
    
                        </Stack>
    
    
                        </Paper>
                           }
    
                            <Transition
                                mounted={currentPaymentMethod.toLocaleLowerCase() === 'credit or debit card'}
                                transition="scale-y"
                                duration={400}
                                timingFunction="ease"
                            >
                                {(styles) =>
                                    <Paper
                                        p="md"
                                        radius="sm"
                                        mt="sm"
                                        style={styles}
                                    >
                                        <Stack sx={{ width: '100%' }}>
                                            {showGuestForm && (
                                                <>
                                                    <TextInput label="Email address" name="email" value={userDetails.email} onChange={handleChange} />
                                                    <Group grow>
                                                        <TextInput label="First name" name="firstName" value={userDetails.firstName} onChange={handleChange} />
                                                        <TextInput label="Last name" name="lastName" value={userDetails.lastName} onChange={handleChange} />
                                                    </Group>
                                                    <TextInput label="Mobile number" name="mobileNumber" value={userDetails.mobileNumber} onChange={handleChange} />
                                                </>
                                            )}
    
                                            
                                            <Checkbox label="Use as billing name" />
                                            <NumberInput
                                                label="Card number"
                                                name="cardNumber"
                                                value={userDetails.cardNumber}
                                                onChange={(value) => handleNumberChange(value, 'cardNumber')}
                                            />
                                            <Group grow>
                                                <NumberInput
                                                    label="MM/YY"
                                                    name="cardExpiry"
                                                    value={parseInt(userDetails.cardExpiry) || 0}
                                                    onChange={(value) => handleChange({ target: { name: 'cardExpiry', value: value?.toString() || '' } } as any)}
                                                />
                                                <NumberInput
                                                    label="CVV"
                                                    name="cardCvv"
                                                    value={userDetails.cardCvv}
                                                    onChange={(value) => handleNumberChange(value, 'cardCvv')}
                                                />
                                            </Group>
                                            <TextInput label="Name on card" name="cardName" value={userDetails.cardName} onChange={handleChange} />
                                            <TextInput label="Postal code" name="postalCode" value={userDetails.postalCode} onChange={handleChange} />
                                            <Checkbox label="Save card for future donations" />
                                        </Stack>
                                    </Paper>
                                }
                            </Transition>
    
    
                            
                        </Paper>
                        <Paper {...paperProps}>
                            <Stack>
                                <Group spacing={4}>
                                    <Checkbox
                                        label="Don't display my name publicly on the fundraiser."
                                        checked={isAnonymous}
                                        onChange={(e) => setIsAnonymous(e.currentTarget.checked)}
                                    />
                                    <Popover width={200} position="bottom" withArrow shadow="md">
                                        <Popover.Target>
                                            <ActionIcon color="primary" variant="subtle">
                                                <IconInfoCircleFilled size={iconSize} />
                                            </ActionIcon>
                                        </Popover.Target>
                                        <Popover.Dropdown>
                                            <Text size="sm">Your name will only be visible to the organizer, any team members and the beneficiary</Text>
                                        </Popover.Dropdown>
                                    </Popover>
                                </Group>
                                <Checkbox
                                    label="Get occasional marketing updates from GoFundMe. You may unsubscribe at any time."
                                />
                            </Stack>
                        </Paper>
                        <Paper {...paperProps}>
                            <Stack>
                                <Text fw={700} size="lg">Your donation</Text>
                                <Group position="apart">
                                    <Text>Your donation</Text>
                                    <Text fw={500}>{`${selectedCurrency} ${campaign?.currency===selectedCurrency ? donationAmount.toFixed(2):(donationAmount * exchangeRate).toFixed(2)}`}</Text>
                                </Group>
    
                                <Group position="apart">
                                    <Text>Platform fee</Text>
                                    <Text fw={500}>{`${selectedCurrency} ${campaign?.currency===selectedCurrency ? (donationAmount*serviceFee).toFixed(2):((donationAmount*serviceFee) * exchangeRate).toFixed(2)}`}</Text>
                                </Group>
                                <Group position="apart">
                                    <Text>Beneficiary Amount</Text>
                                    <Text fw={500}>{`${selectedCurrency} ${campaign?.currency===selectedCurrency?(donationAmount-(donationAmount*serviceFee)).toFixed(2):
                                    ((donationAmount*exchangeRate)-((donationAmount*serviceFee)*exchangeRate)).toFixed(2)
                                        
                                 }`}</Text>
                                </Group>
    
                                <Group position="apart">
                                    <Text>FundReach tip</Text>
                                    <Text fw={500}>{`${selectedCurrency} ${campaign?.currency ===selectedCurrency ? tipAmount && tipAmount.toFixed(2):(tipAmount * exchangeRate).toFixed(2)}`}</Text>
                                </Group>
    
                                <Group position="apart">
                                    <Text>Total due today</Text>
                                   
                                    <Text fw={500}>
                                        {`${selectedCurrency} ${campaign?.currency===selectedCurrency ? ((donationAmount + tipAmount)).toFixed(2):((donationAmount + tipAmount) * exchangeRate).toFixed(2)}`}
    
                                    </Text>
                                </Group>
                                <Button size="lg" onClick={handleSubmit} loading={isSubmitting}>Donate Now</Button>
                            </Stack>
                        </Paper>
                        <Paper {...paperProps}>
                            <Stack>
                                <Text size="sm">By continuing, you agree with <Anchor>FundReach terms</Anchor> and <Anchor>privacy notice.</Anchor></Text>
                                <Text size="sm">Learn more about <Anchor>pricing and fees.</Anchor></Text>
                                <Flex gap="sm">
                                    <ThemeIcon size="lg" variant="light" color="blue">
                                        <IconShieldCheckFilled size={18} />
                                    </ThemeIcon>
                                    <Text size="sm">We guarantee you a full refund for up to a year in the rare case that fraud occurs.&nbsp;<Anchor>See our FundReach Giving Guarantee.</Anchor>
                                    </Text>
                                </Flex>
                            </Stack>
                        </Paper>
                    </Stack>
                </Container>
            </Drawer>
        );
    }

   
};

export default DonationDrawer;
