import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaFacebookMessenger,
  FaLinkedin,
} from "react-icons/fa";

interface SocialShareProps {
  title: string;
  caption: string;
  image: string;
  link: string;
}

const SocialShare: React.FC<SocialShareProps> = ({ title, caption, image, link }) => {
  const encodedTitle = encodeURIComponent(title);
  const encodedCaption = encodeURIComponent(caption);
  const encodedLink = encodeURIComponent(link);
  const encodedImage = encodeURIComponent(image);

  const socialPlatforms = [
    {
      name: "Facebook",
      icon: <FaFacebook size={24} color="#1877F2" />,
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}&quote=${encodedCaption}`,
    },
    {
      name: "X (Twitter)",
      icon: <FaTwitter size={24} color="#1DA1F2" />,
      url: `https://twitter.com/intent/tweet?url=${encodedLink}&text=${encodedCaption}`,
    },
    {
      name: "WhatsApp",
      icon: <FaWhatsapp size={24} color="#25D366" />,
      url: `https://api.whatsapp.com/send?text=${encodedCaption}%20${encodedLink}`,
    },
    {
      name: "Messenger",
      icon: <FaFacebookMessenger size={24} color="#0084FF" />,
      url: `https://www.messenger.com/t/?link=${encodedLink}`,
    },
    {
      name: "LinkedIn",
      icon: <FaLinkedin size={24} color="#0077B5" />,
      url: `https://www.linkedin.com/shareArticle?url=${encodedLink}&title=${encodedTitle}&summary=${encodedCaption}&source=${encodedImage}`,
    },
  ];

  return (
    <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
      {socialPlatforms.map((platform) => (
        <a
          key={platform.name}
          href={platform.url}
          target="_blank"
          rel="noopener noreferrer"
          title={`Share on ${platform.name}`}
          style={{ textDecoration: "none" }}
        >
          {platform.icon}
        </a>
      ))}
    </div>
  );
};

export default SocialShare;
