import React, { useState } from "react";
import { makePostRequest } from '../services/httpHelper';
import ErrorHandler from "../services/ErrorHandler";
import { isEmail, isEmpty, isMobile } from "../utils/FormValidator";
import { Loader,Textarea,TextInput,Button } from '@mantine/core';

import VerifyDeleteDataRequest from "./VerifyDeleteDataRequest";
import { useNavigate } from "react-router-dom";




const PersonalDataDeletionForm = () => {

  const [values, setValues] = useState({
    email: '',
    full_name: '',
    mobile_number:'',
    reason:''
});


  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [reason, setReason] = useState("");


  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
        ...values,
        [e.target.name]: e.target.value
    });
}

const handleReasonChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  setReason(e.target.value);
};



  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setServerError(null);
    setIsSubmitting(true);
    let validationErrors = {};

    if (isEmpty(values.full_name)) {
      alert('Enter your Full Name')
    }

    if (!values.mobile_number) {
      alert("Enter  mobile number")
    } else if (!isMobile(values.mobile_number)) {
      alert('The mobile number you entered is not correct')
      return
    }

    if (isEmpty(values.email) ) {
      alert('Enter email you use to log into your FundReach Account')
      return
    }

    if (!isEmail(values.email)) {
      alert("What you entered is not a valid email address")
      return
    }

    if (isEmpty(reason)) {
      alert("Enter your reason for this request")
      return
    }

    
    if (Object.keys(validationErrors).length !== 0) {
      setIsSubmitting(false);
      return;
    }

    const formData = {
      full_name: values.full_name,
      email: values.email,
      mobile_number:values.mobile_number,
      reason:reason
  }
  console.log(formData)

    const res = await makePostRequest("request-to-delete-personal-data", formData);
    if (res && res.errorType) {
      setIsSubmitting(false);
      setServerError(ErrorHandler(res));
    } else {
      setIsSubmitting(false);
      setServerError(null);
      setSubmitted(true);
    }
  };

  const onClose = () => navigate("/");

  return (
    <div className="container">
      {!submitted ? (
        <form  style={{ fontFamily: "Arial, sans-serif" }}>
          {isSubmitting && <Loader />}
          <h2>Request Personal Data Deletion</h2>
          {serverError && <p style={{ color: "red" }}>{serverError}</p>}

          <div style={{ marginBottom: "15px" }}>

          <TextInput label="Full Name" placeholder="Full Name" name="full_name" value={values.full_name} onChange={handleChange} required/>
         
          </div>

          <div style={{ marginBottom: "15px" }}>
          
            <TextInput label="Email" placeholder="Fund Reach Account Email" name="email" value={values.email} onChange={handleChange} required/>
          </div>

          <div style={{ marginBottom: "15px" }}>
          <TextInput label="Mobile Number" placeholder="Mobile Number" name="mobile_number" value={values.mobile_number} onChange={handleChange}/>

           
          </div>

          

          <div style={{ marginBottom: "15px" }}>
             <Textarea
               label="Reason for Deletion"
                placeholder="Enter the reason..."
                value={reason}
                onChange={handleReasonChange}
                                            />

          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
             <Button fullWidth mt="xl" onClick={onClose} style={{backgroundColor:"#ddd",color:"#fff"}}>
                                   Cancel
                                </Button>
            
            <Button fullWidth mt="xl"  disabled={isSubmitting} onClick={(e)=>handleSubmit(e)}>
                                   Submit
                                </Button>
          </div>
        </form>
      ) : (
        <div style={{ textAlign: "center" }}>
          <VerifyDeleteDataRequest email={values.email||''} />
        </div>
      )}
    </div>
  );
};

export default PersonalDataDeletionForm;