import { useState, useEffect } from 'react';
import { makePostRequest } from '../services/httpHelper';
import { Card, TextInput, Button, Text, Loader, Group } from '@mantine/core';
import { useNavigate } from 'react-router-dom';


type emailProp = {
    email:String
    
  };

const VerifyDeleteDataRequest:React.FC<emailProp>  = ({ email }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCanResend(true);
    }
  }, [countdown]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setVerificationCode(e.target.value);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!verificationCode.trim()) {
      setError('Enter Verification Code');
      return;
    }
    setIsSubmitting(true);
    const res = await makePostRequest('verify-request-to-delete-personal-data', { verificationCode, email });
    setIsSubmitting(false);
    res?.errorType ? setError(res.errorMessage) : setIsSuccess(true);
  };

  const handleResend = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!canResend) return;
    setIsSubmitting(true);
    const res = await makePostRequest('resend-delete-personal-data-verification-code', { email });
    setIsSubmitting(false);
    res?.errorMessage && setError(res.errorMessage);
    setCountdown(60);
    setCanResend(false);
  };


  return (
    <div style={{ maxWidth: 400, margin: 'auto' }}>
      {isSuccess ? (
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Text align="center" weight={500} size="lg">Request Submitted</Text>
          <Text align="center" mt="sm">
            Your request to delete your personal data has been received. We will process it within 7 business days.
            If you have further questions, contact us at{' '}
            <a href="mailto:customercare@roshtech.co.ke">customercare@roshtech.co.ke</a>.
          </Text>
          <Button fullWidth mt="md" onClick={() => navigate('/')}>Close</Button>
        </Card>
      ) : (
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Text align="center" weight={500} size="lg">Verify Phone Number</Text>
          <Text align="center" mt="sm">Verification code has been sent to {email}.</Text>
          {error && <Text color="red" align="center" mt="sm">{error}</Text>}
          <form>
            <TextInput
              placeholder="Enter Verification Code"
              value={verificationCode}
              onChange={handleChange}
              mt="md"
            />
            <Group position="center" mt="md">
              <Button type="submit" color="green" disabled={isSubmitting || !verificationCode.trim()} onClick={(e)=>handleSubmit(e)}>
                {isSubmitting ? <Loader size="sm" /> : 'Verify'}
              </Button>
            </Group>
          </form>
          <Text align="center" mt="md">
            {canResend ? 'You can now resend the verification code.' : `Resend in ${countdown} seconds.`}
          </Text>
          <Group position="center" mt="md">
            <Button onClick={(e)=>handleResend(e)} color="blue" disabled={!canResend}>Resend Code</Button>
          </Group>
        </Card>
      )}
    </div>
  );
};

export default VerifyDeleteDataRequest;
