import {createBrowserRouter,} from "react-router-dom";
import {
    AboutUs,
    CampaignCategory,
    CampaignDetailsPage,
    CampaignStatementPage,
    CampaignsPage,
    ChangePasswordPage,
   ContactUsPage,
   // CreateCamPage,
    CreateCampaignPage,
    DashboardPage,
    DetailError404Page,
    Error404Page,
    Faqs,
    FollowingCampaignsPage,
    ForgotPasswordPage,
    HomePage,
    HowItWorksPage,
    LoginPage,
    SendUserMessage,
    SetNewPasswordPage,
    SignupPage,
    UserDashboardPage,
    UserFundedCampaigns,
    UserProfilePage,
    UserSettingsPage,
    VerifyEmailPage,
    VerifyResetPasswordPage,
    WhyFundReach
} from "../pages";
import {DashboardLayout, PublicLayout} from "../layout";
import ProtectedRoute from "./ProtectedRoute";
import Blog from "../pages/Blog";
import Post from "../pages/Post";
import DonationThankYouPage from "../components/DonationThankYouPage";
import PaymentFailed from "../components/PaymentFailed";
import FundReachTermsOfService from "../pages/policies/Terms";
import FundReachPrivacyPolicy from "../pages/policies/PrivacyPolicy";
import WithdrawalForm from "../components/WithdrawalForm";
import Withdrawal from "../components/Withdrawal";
import WithdrawalSuccess from "../components/WithdrawalSuccess";
import WithdrawalAdminApproval from "../components/WithdrawalAdminApproval";
import AuthWithdrawalInitiator from "../components/AuthWithdrawalInitiator";
import WithdrawalVerifyOtp from "../components/WithdrawalVerifyOtp";
//import AddAdminForm from "../components/AddAdminForm";
import ManageCampaignPage from "../pages/ManageCampaign";
import ManageAdminPage from "../pages/ManageAdmins";
import ManageTeamPage from "../pages/ManageTeams";
import LoginFailed from "../pages/LoginFailed";
import AuthSuccess from "../pages/AuthSuccess";
import PendingCampaignsPage from "../pages/PendingCampaignsPage";
import ApprovePendingCampaigns from "../pages/ApprovePendingCampaigns";
import UnauthorizedPage from "../pages/Unauthorized";
import ManageRolesPage from "../pages/ManageRoles";
import ManageDeletePersonalData from "../pages/ManageDeletePersonalData";
import ManageUsersPage from "../pages/ManageUsers";
import CapturePaypalOrder from "../pages/PaypalOrderApproved";
import AdminDashboardLayout from "../layout/AdminDashboardLayout";
import DataDeletionInfo from "../pages/DataDeletionInfo";
//import PersonalDataDeletionForm from "../pages/PersonalDataDeleteForm";
//import VerifyDeleteDataRequest from "../pages/VerifyDeleteDataRequest";

const router = createBrowserRouter([
    {
        path: "/",
        element: <PublicLayout compressedNav/>,
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <HomePage/>
            }
        ]
    },

    {
        path: "unauthorized",
        element: <PublicLayout compressedNav/>,
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <UnauthorizedPage/>
            }
        ]
    },
    {
        path: "login",
        element: <LoginPage/>,
        errorElement: <Error404Page/>,
    },

    {
        path: "login-failed",
        element: <LoginFailed/>,
        errorElement: <Error404Page/>,
    },
    {
        path: "auth-success",
        element: <AuthSuccess/>,
        errorElement: <Error404Page/>,
    },
    {
        path: "campaign-details/:id",
        element: <CampaignDetailsPage/>,
        errorElement: <Error404Page/>,
    },
    {
        path: "signup",
        element: <SignupPage/>,
        errorElement: <Error404Page/>,
    },

    {
        path: "verify-email/:email",
        element: <VerifyEmailPage/>,
        errorElement: <Error404Page/>,
    },

    {
        path: "forgot-password",
        element: <ForgotPasswordPage/>,
        errorElement: <Error404Page/>,
    },

    {
        path: "verify-reset-password-code/:email",
        element: <VerifyResetPasswordPage/>,
        errorElement: <Error404Page/>,
    },

    {
        path: "set-password",
        element: <SetNewPasswordPage/>,
        errorElement: <Error404Page/>,
    },

    {
        path: "chnage-password",
        element: <ChangePasswordPage/>,
        errorElement: <Error404Page/>,
    },

    {
        path: "capturing-order",
        element: <CapturePaypalOrder/>,
        errorElement: <Error404Page/>,
    },

    {
        path: "donation-success",
        element: <DonationThankYouPage/>,
        errorElement: <Error404Page/>,
    },

    {
        path: "payment-failed",
        element: <PublicLayout/>,
        errorElement: <Error404Page/>,
        children:[
            {
                path: '',
                index: true,
                element: <PaymentFailed/>
            }
        ]
    },

    {
        path: "terms",
        element: <PublicLayout/>,
        errorElement: <Error404Page/>,
        children:[
            {
                path: '',
                index: true,
                element: <FundReachTermsOfService/>
            }
        ]
    },

    {
        path: "privacy",
        element: <PublicLayout/>,
        errorElement: <Error404Page/>,
        children:[
            {
                path: '',
                index: true,
                element: <FundReachPrivacyPolicy/>
            },

            {
                path: 'delete-personal-data-info',
                index: true,
                element: <DataDeletionInfo/>
            }
        ]
    },


    {
        path: "change-password",
        element: <ChangePasswordPage/>,
        errorElement: <Error404Page/>,
    },

 



    {
        path: "how-it-works",
        element: <PublicLayout/>,
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <HowItWorksPage/>
            }
        ]
    },
    {
        path: "campaigns",
        element: <PublicLayout/>,
        children: [
            {
                path: "",
                index: true,
                element: <CampaignsPage/>,
                errorElement: <Error404Page/>,
            },
            {
                path: ":id",
                element: <CampaignDetailsPage/>,
                errorElement: <DetailError404Page/>
            },
            {
                path: "category/:categoryId",
                element: <CampaignsPage />,
                errorElement: <Error404Page />
            }
        ]
    },

    {
        path: "campaign-categories",
        element: <PublicLayout/>,
        children: [
            {
                path: "",
                index: true,
                element: <CampaignCategory/>,
                errorElement: <Error404Page/>,
            },
          
        ]
    },
    {
        path: "frequently-asked-questions",
        element: <PublicLayout/>,
        children: [
            {
                path: "",
                index: true,
                element: <Faqs/>,
                errorElement: <Error404Page/>,
            },
          
        ]
    },

    {
        path: "why-fund-reach",
        element: <PublicLayout/>,
        children: [
            {
                path: "",
                index: true,
                element: <WhyFundReach/>,
                errorElement: <Error404Page/>,
            },
           
        ]
    },

    {
        path: "about-us",
        element: <PublicLayout/>,
        children: [
            {
                path: "",
                index: true,
                element: <AboutUs/>,
                errorElement: <Error404Page/>,
            },
           
        ]
    },

    {
        path: "blog",
        element: <PublicLayout/>,
        children: [
            {
                path: "",
                index: true,
                element: <Blog/>,
                errorElement: <Error404Page/>,
            },
           
        ]
    },

    {
        path: "contact-us",
        element: <PublicLayout/>,
        children: [
            {
                path: "",
                index: true,
                element: <ContactUsPage/>,
                errorElement: <Error404Page/>,
            },
           
        ]
    },

    {
        path: "blog",
        element: <PublicLayout/>,
        children: [
            {
                path: "id",
                index: true,
                element: <Post/>,
                errorElement: <Error404Page/>,
            },
           
        ]
    },
   
    {
        path: "dashboard",
        element: (
            <ProtectedRoute isAllowed={true} >
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <UserDashboardPage/>
            },
            {
                path: 'user/profile',
                index: true,
                element: <UserProfilePage />
            },

            {
                path: 'campaign/manage/:id',
                index: true,
                element: <ManageCampaignPage />
            },
            {
                path: ':id/admins',
                index: true,
                element: <ManageAdminPage/>
            },

            {
                path: ':id/promoters',
                index: true,
                element: <ManageTeamPage/>
            }
        ]
    },


    

    {
        path: "admin-dashboard",
        element: (
            <ProtectedRoute isAllowed={true} requiredRoles={["admin"]}>
              <AdminDashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <DashboardPage/>
            },

            {
                path: 'roles',
                index: true,
                element: <ManageRolesPage/>
            },
            {
                path: 'users',
                index: true,
                element: <ManageUsersPage/>
            },
            {
                path: 'delete-personal-data',
                index: true,
                element: <ManageDeletePersonalData/>
            }
        ]
    },
  

    {
        path: "create-campaign",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <CreateCampaignPage/>
            }
        ]
    },


    {
        path: "following-campaigns",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <FollowingCampaignsPage/>
            }
        ]
    },

    {
        path: "pending-campaigns",
        element: (
            <ProtectedRoute isAllowed={true} requiredRoles={["admin"]}>
              <AdminDashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <PendingCampaignsPage/>
            }
        ]
    },


    {
        path: "user-funded-campaigns",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <UserFundedCampaigns/>
            }
        ]
    },

   

    {
        path: "campaign/manage/pending",
        element: (
            <ProtectedRoute isAllowed={true} requiredRoles={["admin"]}>
              <AdminDashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: ':id',
                index: true,
                element: <ApprovePendingCampaigns/>
            },

           
            
        ]
    },

    {
        path: "campaign-statement",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <CampaignStatementPage/>
            }
        ]
    },
    {
        path: "message-fund-raiser",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: ':id',
                index: true,
                element: <SendUserMessage/>
            }
        ]
    },


    {
        path: "initiate-withdrawal",
        element: (
            <ProtectedRoute isAllowed={true} >
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: ':id',
                index: true,
                element: <WithdrawalForm/>
            }
        ]
    },


    {
        path: "verify-withdrawal-initiator",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: ':id',
                index: true,
                element: <AuthWithdrawalInitiator/>
            }
        ]
    },

    {
        path: "request-admin-approval",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: ':id',
                index: true,
                element: <WithdrawalAdminApproval/>
            }
        ]
    },
    {
        path: "verify-admin-approval",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: ':id',
                index: true,
                element: <WithdrawalVerifyOtp/>
            }
        ]
    },


  
//834354


    {
        path: "process-withdrawal",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: ':id',
                index: true,
                element: <Withdrawal/>
            }
        ]
    },


    {
        path: "withdrawal-success",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: '',
                index: true,
                element: <WithdrawalSuccess/>
            }
        ]
    },

    {
        path: "request-admin-approval",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: 'id',
                index: true,
                element: <WithdrawalAdminApproval/>
            }
        ]
    },

    {
        path: "verify-withdrawal-initiator",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: 'id',
                index: true,
                element: <AuthWithdrawalInitiator/>
            }
        ]
    },

    {
        path: "verify-admin-approval",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: 'id',
                index: true,
                element: <WithdrawalVerifyOtp/>
            }
        ]
    },

    {
      /*  path: "add-campaign-admin",
        element: (
            <ProtectedRoute isAllowed={true}>
              <DashboardLayout />
            </ProtectedRoute>
          ),
        errorElement: <Error404Page/>,
        children: [
            {
                path: 'id',
                index: true,
                element: <AddAdminForm/>
            }
        ]*/
    },



    {
        path: "account-setting",
        element: <UserSettingsPage/>,
        errorElement: <Error404Page/>,
      
    },
]);


export default router;
