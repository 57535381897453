import { useState } from "react";
import { Button, Modal, Container, Title, Text, List, ThemeIcon } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import PersonalDataDeletionForm from "./PersonalDataDeleteForm";
import Contact from "./ContactUs";

const DataDeletionInfo: React.FC = () => {
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  return (
    <Container>
      <Title order={1}>Delete Your Personal Data</Title>
      <Text>
        At FundReach, we value your privacy and respect your rights. If you wish to delete
        your personal data, you can easily request the deletion through the options below.
        Please note that while most personal data can be deleted, certain data may be
        protected by law or necessary for regulatory purposes, and cannot be removed.
      </Text>

      <Title order={2} mt="md">What Data Can Be Deleted?</Title>
      <List spacing="sm" icon={<ThemeIcon color="blue" size={24} radius="xl"><IconCheck size={16} /></ThemeIcon>}>
        <List.Item>Your Profile Information: Name, contact details, and profile pictures</List.Item>
        <List.Item>Group Information: Data related to the groups you have created or joined</List.Item>
        <List.Item>Financial Data: Non-regulatory data such as saved preferences or non-transactional history</List.Item>
      </List>

      <Title order={2} mt="md">What Data Cannot Be Deleted?</Title>
      <List spacing="sm" icon={<ThemeIcon color="red" size={24} radius="xl"><IconCheck size={16} /></ThemeIcon>}>
        <List.Item>Transaction Records: To comply with financial regulations, records of deposits, withdrawals, and loans must be retained.</List.Item>
        <List.Item>Legal and Compliance Data: Any personal data necessary for legal obligations, audits, or disputes may not be deleted.</List.Item>
      </List>

      <Title order={2} mt="md">How to Request Data Deletion</Title>
      <List spacing="sm" type="ordered">
        <List.Item>Review the data to be deleted: Ensure you understand the implications as it will be permanent.</List.Item>
        <List.Item>Submit Your Request: Click the "Request Data Deletion" button below.</List.Item>
        <List.Item>Confirmation: You will receive an email detailing the data to be deleted, processed within 7 business days.</List.Item>
      </List>

     

      <Button mt="md" color="blue" onClick={handleOpenForm}>
        Request Data Deletion
      </Button>

      <Modal opened={showForm} onClose={handleCloseForm} title="Request Data Deletion" centered>
        <PersonalDataDeletionForm />
      </Modal>

      <Title order={2} mt="xl">Contact Us</Title>
      <Text>If you need further clarification on the deletion process, please reach out to us:</Text>
      <Contact />
    </Container>
  );
};

export default DataDeletionInfo;
